import React from 'react'

import SEO from '~/components/seo'
import TermsAndConditions from '../components/TermsAndConditions'
import Footer from '../components/Footer'

const TermsAndConditionsPage = () => (
  <>
    <SEO
      title="Terms and Conditions"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <TermsAndConditions />
    <Footer />
  </>
)

export default TermsAndConditionsPage
