import React from 'react'
import styled from 'styled-components'
import {
  ShippingText,
  LargerHeading,
  DetailsText,
  CrumbCon,
  HeadingText,
} from '../../styles'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import media from '../../styles/media'
import { isMobile } from 'react-device-detect'
import Navigation from '../Navigation'

const TCCon = styled.div`
  margin: var(--gutter-m) var(--gutter-s);
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${media.laptop`
    margin: var(--gutter-m) var(--gutter-m);
  `}
`

const TermsAndConditions = () => {
  return (
    <>
      <Navigation />
      <CrumbCon>
        <Breadcrumb>
          <Breadcrumb.Item href="/products">
            <p className="blue backArrow">
              &#x25C2;
            </p>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <p>/</p>
          <Breadcrumb.Item href="/termsandconditions">
            Terms &amp; Conditions
          </Breadcrumb.Item>
        </Breadcrumb>
      </CrumbCon>
      <TCCon>
        {!isMobile && (
          <div className="heading">
            <HeadingText className="black smaller">
              Terms &amp; Conditions
            </HeadingText>
            <DetailsText>Last updated: 19 August 2020</DetailsText>
          </div>
        )}
        {isMobile && (
          <>
            <DetailsText>Last updated: 19 August 2020</DetailsText>
            <LargerHeading>Terms &amp; Conditions</LargerHeading>{' '}
          </>
        )}
        {!isMobile && (
          <ShippingText className="bold">
            <br />
            <br /> Quza IT (“THE COMPANY”) WEBSITE TERMS OF SERVICE
            <br />
            <br />
            THESE TERMS OF SERVICE (“TERMS”) ARE EFFECTIVE AS OF: 2020
            “EFFECTIVE DATE”
            <br />
            <br />
            <br /> Introduction
          </ShippingText>
        )}
        {isMobile && (
          <ShippingText>
            <br />
            <br /> Quza IT (“THE COMPANY”) WEBSITE TERMS OF SERVICE
            <br />
            <br />
            THESE TERMS OF SERVICE (“TERMS”) ARE EFFECTIVE AS OF: 2020
            “EFFECTIVE DATE”
            <br />
            <br />
            <br /> Introduction
          </ShippingText>
        )}
        <ShippingText>
          <br /> These Terms will apply fully and affect a User’s use of the
          website https://quzahpshop.co.za/pages/terms-conditions (“the
          Website”). By using this Website, a User agrees to accept the Terms
          contained herein in full.
          <br />
          <br /> Should a User not agree to the Terms contained herein, a User
          must immediately desist from using this Website.
          <br />
          <br /> Minors are not allowed to use this Website.
          <br />
          <br />
        </ShippingText>
        <ShippingText className="bold">
          <br /> Intellectual Property Rights
        </ShippingText>
        <ShippingText>
          <br />
          The Company and/or the Company’s licensors own all the intellectual
          property rights and materials as are contained on this Website.
          <br />
          <br /> A User is granted a limited license only for purposes of
          utilising this Website.
          <br />
          <br />
        </ShippingText>
        <ShippingText className="bold">
          <br /> Restrictions
        </ShippingText>
        <ShippingText>
          <br />
          A User may not:
          <br />
          <br />
          publish or mirror any of this Website’s material in any media
          whatsoever;
          <br />
          <br /> use this Website for any objectionable or unlawful purpose,
          including the posting of any threatening, libellous, defamatory,
          obscene, scandalous, inflammatory, pornographic, profane or spam
          material;
          <br />
          <br />
          take any action that may impose an unreasonable or disproportionately
          large load on this Website’s infrastructure of any nature;
          <br />
          <br /> use this Website in any manner would result in a User breaching
          any applicable legislation or licensing obligations (including with
          respect to privacy) or any obligations a User may owe to third
          parties;
          <br />
          <br /> conduct any activity which compromises or breaches any
          third-party’s patent rights, trademark, copyright or other
          intellectual property rights;
          <br />
          <br /> introduce any virus, worm, trojan horse, malicious code or
          other program which may damage computers or other computer-based
          equipment of the Company or affect the performance of this Website
          <br />
          <br /> engage in any data mining, data harvesting, data extracting or
          any other similar activity in relation to this Website;
          <br />
          <br /> use this Website contrary to applicable laws and regulations,
          or in any way may cause harm to the Website, or to any person or
          business entity;
          <br />
          <br /> use this Website to engage in any advertising or marketing
          other than in a manner expressly permitted by the Website;
          <br />
          <br /> crawl, spider or scrape the content of the Website, except to
          the extent required by recognised search engines (e.g. Google) for the
          purposes of indexing this Website; or
          <br />
          <br /> provide unauthorised interfaces to the Website.
          <br />
          <br /> Certain areas of this Website are restricted from being
          accessed by a User, and the Company may further restrict access by a
          User to any areas of this Website, at any time, in its absolute
          discretion. Any user ID and password a User may have for this Website
          are confidential and a User must maintain confidentiality as well.
        </ShippingText>
      </TCCon>
    </>
  )
}

export default TermsAndConditions
